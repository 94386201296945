<template>
  <div>
    <Header :showMenuIcon="false" :showSearchIcon="false" :centerLogo="true" />
    <div class="container">
      <div class="text-holder mt-3">
        <span class="title main-title">
          TERMOS E CONDIÇÕES GERAIS DE USO E DE COMPRA E VENDA DO APLICATIVO "Do
          It"
        </span>
      </div>
      <div class="text-holder mt-2">
        <p>
          Estes termos e condições gerais de uso e de compra e venda aplicam-se
          aos serviços prestados pela pessoa jurídica
          <b>FLÁVIO ERNESTO ROCHA MATOS</b>
          ME, devidamente registrada sob o CNPJ n. 18155946000192, e-mail:
          <a class="link" href="mailto:contato@appdoit.com.br"
            >contato@appdoit.com.br</a
          >, com sede em:
        </p>
        <p class="mt-1 ml-2">Dourados - MS</p>
        <p class="ml-2">CEP 79823-461</p>
        <p class="mt-1 ml-2">representada por:</p>
        <p class="mt-1">
          <b class="ml-2">Flávio Ernesto Rocha Matos</b>, na qualidade de: CEO.
        </p>
        <p class="mt-1">doravante denominada <b>EDITOR DO APLICATIVO</b>,</p>
        <p class="mt-1">por meio do aplicativo Do It.</p>
      </div>
      <div class="text-holder mt-3">
        <span class="title">I. DO OBJETO</span>
        <p>
          O aplicativo Do It caracteriza-se pela prestação dos seguintes
          serviços, incluindo a venda à distância e por meio eletrônico de
          produtos e de serviços:
        </p>
        <p class="mt-1 ml-1">
          Edição de templates / e-cards para uso pessoal e profissional.
        </p>
      </div>
      <div class="text-holder mt-3">
        <span class="title">
          II. DA ACEITAÇÃO DOS TERMOS E CONDIÇÕES GERAIS DE USO E DE COMPRA E
          VENDA
        </span>
        <p>
          Todos aqueles que desejarem ter acesso aos serviços ofertados através
          do aplicativo <b>Do It</b> deverão, primeiramente, se informar sobre
          as regras que compõem o presente instrumento, as quais ficarão
          disponíveis para pronta e ampla consulta, em link direto no próprio
          aplicativo.
        </p>
        <p class="mt-1">
          Ao utilizar o aplicativo <b>Do It</b>, o usuário aceita integralmente
          as presentes normas e compromete-se a observá-las, sob risco de
          aplicação das penalidades cabíveis. Antes de iniciar qualquer
          navegação no aplicativo, o usuário deverá cientificar-se de eventuais
          modificações ou atualizações ocorridas neste termos.
        </p>
        <p class="mt-1">
          O usuário poderá ainda ser avisado por e-mail ou diretamente no
          próprio aplicativo sobre eventuais mudanças nas presentes normas.
        </p>
        <p class="mt-1">
          Caso não concorde com quaisquer das regras aqui descritas, o usuário
          deve, imediatamente, abster-se de utilizar o serviço. Se for de seu
          interesse, poderá, ainda, entrar em contato com o serviço de
          atendimento ao cliente, para apresentarlhe as suas ressalvas
        </p>
      </div>
      <div class="text-holder mt-3">
        <span class="title">III. DA NAVEGAÇÃO</span>
        <p>
          O editor do aplicativo <b>Do It</b> se compromete a utilizar todas as
          soluções técnicas à sua disposição para permitir o acesso ao serviço
          24 (vinte e quatro) horas por dia, 7 (sete) dias por semana.
          Entretanto, ele poderá, a qualquer momento, interromper, limitar ou
          suspender o acesso ao aplicativo ou a algumas de suas páginas, a fim
          de realizar atualizações, modificações de conteúdo ou qualquer outra
          ação julgada necessária para o seu bom funcionamento.
        </p>
        <p class="mt-1">
          Os presentes termos e condições gerais de uso e de compra e venda se
          aplicam a todas as extensões do aplicativo <b>Do It</b> em redes
          sociais ou em comunidades, tanto as já existentes, quanto aquelas
          ainda a serem implementadas.
        </p>
      </div>
      <div class="text-holder mt-3">
        <span class="title">IV. DA GESTÃO DO APLICATIVO</span>
        <p>
          Para a boa gestão, o editor do aplicativo Do It poderá, a qualquer
          momento:
        </p>
        <p class="mt-1 ml-1">
          a) suspender, interromper ou limitar o acesso a todo ou a parte do
          aplicativo a uma categoria específica de internautas;
        </p>
        <p class="mt-1 ml-1">
          b) remover toda informação que possa perturbar o funcionamento do
          aplicativo ou que esteja em conflito com normas de Direito brasileiro
          ou de Direito internacional;
        </p>
        <p class="mt-1 ml-1">
          c) suspender o aplicativo, a fim de realizar atualizações e
          modificações.
        </p>
      </div>
      <div class="text-holder mt-3">
        <span class="title">V. DO CADASTRO</span>
        <p>
          Os serviços disponibilizados no aplicativo <b>Do It</b> apenas poderão
          ser acessados por pessoas plenamente capazes, conforme o Direito
          brasileiro. Todos aqueles que não possuírem plena capacidade civil -
          menores de 18 anos não emancipados, pródigos, ébrios habituais ou
          viciados em tóxicos e pessoas que não puderem exprimir sua vontade,
          por motivo transitório ou permanente - deverão ser devidamente
          assistidos por seus representantes legais, que se responsabilizarão
          pelo cumprimento das presentes regras.
        </p>
        <p class="mt-1">
          As pessoas jurídicas também poderão se cadastrar, por meio de seus
          representantes legais.
        </p>
        <p class="mt-1">
          Ao usuário, será permitido manter apenas uma conta junto ao aplicativo
          <b>Do It</b>. Contas duplicadas serão automaticamente desativadas pelo
          editor do aplicativo, sem prejuízo de demais penalidades cabíveis.
        </p>
        <p class="mt-1">
          Para o devido cadastramento junto ao serviço, o usuário deverá
          fornecer integralmente os dados requeridos. Todas as informações
          fornecidas pelo usuário devem ser precisas, verdadeiras e atualizadas.
          Em qualquer caso, o usuário responderá, em âmbito cível e criminal,
          pela veracidade, exatidão e autenticidade dos dados informados.
        </p>
        <p class="mt-1">
          O usuário deverá fornecer um endereço de e-mail válido, através do
          qual o aplicativo realizará todos os contatos necessários. Todas as
          comunicações enviadas para o referido endereço serão consideradas
          lidas pelo usuário, que se compromete, assim, a consultar regularmente
          suas mensagens recebidas e a respondê-las em prazo razoável.
        </p>
        <p class="mt-1">
          Após a confirmação de seu cadastro, o usuário possuirá um login
          (apelido) e uma senha pessoais, que deverão ser por ele utilizados
          para o acesso a sua conta no aplicativo <b>Do It</b>. Estes dados de
          conexão não poderão ser informados pelo usuário a terceiros, sendo de
          sua inteira responsabilidade o uso que deles seja feito. O usuário
          compromete-se a comunicar imediatamente ao editor do aplicativo
          quaisquer atividades suspeitas ou inesperadas em sua conta.
        </p>
        <p class="mt-1">
          Não será permitido ceder, vender, alugar ou transferir, de qualquer
          forma, a conta, que é pessoal e intransferível.
        </p>
        <p class="mt-1">
          Será automaticamente descadastrado o usuário que descumprir quaisquer
          das normas contidas no presente instrumento, sendo-lhe vedado realizar
          nova inscrição no aplicativo.
        </p>
        <p class="mt-1">
          O usuário poderá, a qualquer tempo e sem necessidade de justificação,
          requerer o cancelamento de seu cadastro junto ao aplicativo
          <b>Do It</b>. O seu descadastramento será realizado o mais rapidamente
          possível, desde que não sejam verificados débitos em aberto.
        </p>
      </div>
      <div class="text-holder mt-3">
        <span class="title">VI. DAS RESPONSABILIDADES</span>
        <p>
          O editor se responsabilizará pelos defeitos ou vícios encontrados nos
          serviços prestados pelo aplicativo <b>Do It</b>, desde que tenha lhes
          dado causa. Defeitos ou vícios técnicos ou operacionais originados no
          próprio sistema do usuário não serão de responsabilidade do editor.
        </p>
        <p class="mt-1">
          O editor responsabiliza-se apenas pelas informações que foram por ele
          diretamente divulgadas. Quaisquer informações incluídas pelos
          usuários, tais como em comentários e em perfis pessoais, serão de
          inteira responsabilidade dos próprios.
        </p>
        <p class="mt-1">O usuário é responsável, ainda:</p>
        <p class="mt-1 ml-1">
          a) pela correta utilização do aplicativo e de seus serviços, prezando
          pela boa convivência, pelo respeito e pela cordialidade no
          relacionamento com os demais usuários;
        </p>
        <p class="mt-1 ml-1">
          b) pelo cumprimento das regras contidas neste instrumento, bem como
          normas de Direito nacional e de Direito internacional;
        </p>
        <p class="mt-1 ml-1">
          c) pela proteção dos dados de acesso à sua conta (login e senha).
        </p>
        <p class="mt-1">O editor não será responsável:</p>
        <p class="mt-1 ml-1">
          a) pelas características intrínsecas da internet, principalmente
          relativas à confiabilidade e à procedência das informações circulando
          nesta rede;
        </p>
        <p class="mt-1 ml-1">
          b) pelos conteúdos ou atividades ilícitas praticadas através de seu
          aplicativo.
        </p>
      </div>
      <div class="text-holder mt-3">
        <span class="title">VII. DOS LINKS EXTERNOS</span>
        <p>
          O aplicativo <b>Do It</b> pode conter links externos redirecionando o
          usuário para outras páginas da internet, sobre os quais o editor não
          exerce controle. Apesar das verificações prévias e regulares
          realizadas pelo editor, ele se isenta de qualquer responsabilidade
          sobre o conteúdo encontrado nestes sites e serviços.
        </p>
        <p class="mt-1">
          Poderão ser incluídos links nas páginas e nos documentos do aplicativo
          <b>Do It</b>, desde que não sirvam para fins comerciais ou
          publicitários. Esta inclusão dependerá de autorização prévia do
          editor.
        </p>
        <p class="mt-1">
          Não será autorizada a inclusão de páginas que divulguem quaisquer
          tipos de informações ilícitas, violentas, polêmicas, pornográficas,
          xenofóbicas, discriminatórias ou ofensivas.
        </p>
        <p class="mt-1">
          O editor se reserva o direito de retirar a qualquer momento um link
          levando ao seu próprio serviço, se a página de origem não estiver
          conforme a sua política editorial.
        </p>
      </div>
      <div class="text-holder mt-3">
        <span class="title">VIII. DOS DIREITOS AUTORAIS</span>
        <p>
          A estrutura do aplicativo <b>Do It</b>, bem como os textos, os
          gráficos, as imagens, as fotografias, os sons, os vídeos e as demais
          aplicações informáticas que o compõem são de propriedade do editor e
          são protegidas pela legislação brasileira e internacional referente à
          propriedade intelectual.
        </p>
        <p class="mt-1">
          Qualquer representação, reprodução, adaptação ou exploração parcial ou
          total dos conteúdos, marcas e serviços propostos pelo aplicativo, por
          qualquer meio que seja, sem autorização prévia, expressa e escrita do
          editor, é estritamente vedada, podendo-se recorrer às medidas cíveis e
          penais cabíveis. Estão excluídos desta previsão apenas os elementos
          que estejam expressamente designados no aplicativo como livres de
          direitos autorais.
        </p>
        <p class="mt-1">
          O acesso não gera para o usuário qualquer direito de propriedade
          intelectual relativo a elementos do aplicativo, os quais restam sob a
          propriedade exclusiva do editor.
        </p>
        <p class="mt-1">
          É vedado ao usuário incluir no aplicativo dados que possam modificar o
          seu conteúdo ou sua aparência.
        </p>
      </div>
      <div class="text-holder mt-3">
        <span class="title">IX. DA POLÍTICA DE PRIVACIDADE</span>
        <b>
          <p>1. Informações gerais</p>
          <p class="mt-1">
            Essa seção contém informações a respeito do tratamento de dados
            pessoais do usuário, total ou parcialmente, automatizados ou não,
            realizado pelo aplicativo e que poderão ou não serem armazenados. O
            objetivo é fornecer orientações a respeito das informações
            coletadas, os motivos da coleta e como o usuário poderá atualizar,
            gerenciar, exportar ou excluir essas informações.
          </p>
          <p class="mt-1">
            A política de privacidade do aplicativo está de acordo com a Lei
            federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet),
            com a Lei federal n. 13.709, de 14 de agosto de 2018 (Lei de
            Proteção de Dados Pessoais) e com o Regulamento UE n. 2016/679 de 27
            de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados
            Pessoais).
          </p>
          <p class="mt-1">
            Esta política de privacidade poderá ser atualizada em decorrência de
            uma eventual atualização normativa, razão pela qual se convida o
            usuário a consultar periodicamente esta seção.
          </p>
          <p class="mt-2">2. Direitos do usuário</p>
          <p class="mt-1">
            O aplicativo se compromete a cumprir as normas dispostas pelo
            Regulamento Geral Europeu de Proteção de Dados Pessoais (RGPD), em
            respeito aos seguintes princípios:
          </p>
          <ul>
            <li class="mt-1">
              Seus dados pessoais serão processados de forma lícita, leal e
              transparente (licitude, lealdade e transparência);
            </li>
            <li>
              Seus dados pessoais serão coletados apenas para finalidades
              determinadas, explícitas e legítimas, não podendo ser tratados
              posteriormente de uma forma incompatível com essas finalidades
              (limitação das finalidades);
            </li>
            <li>
              Seus dados pessoais serão coletados de forma adequada, pertinente
              e limitada às necessidades do objetivo para os quais eles são
              processados (minimização dos dados);
            </li>
            <li>
              Seus dados pessoais serão exatos e atualizados sempre que
              necessário, de maneira que os dados inexatos sejam apagados ou
              retificados quando possível (exatidão);
            </li>
            <li>
              Seus dados pessoais serão conservados de uma forma que permita a
              identificação dos titulares dos dados apenas durante o período
              necessário para as finalidades para as quais são tratados
              (limitação da conservação);
            </li>
            <li>
              Seus dados pessoais serão tratados de forma segura, protegidos do
              tratamento não autorizado ou ilícito e contra a sua perda,
              destruição ou danificação acidental, adotando as medidas técnicas
              ou organizativas adequadas (integridade e confidencialidade).
            </li>
          </ul>
          <p class="mt-1">
            O usuário do aplicativo possui os seguintes direitos, conferidos
            pela Lei federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção
            de Dados Pessoais) e pelo Regulamento Geral Europeu de Proteção de
            Dados Pessoais (RGPD):
          </p>
          <ul>
            <li class="mt-1">
              Direito de confirmação e acesso: é o direito do usuário de obter
              do aplicativo a confirmação de que os dados pessoais que lhe digam
              respeito são ou não objeto de tratamento e, se for esse o caso, o
              direito de acessar os seus dados pessoais;
            </li>
            <li>
              Direito de retificação: é o direito do usuário de obter do
              aplicativo, sem demora injustificada, a retificação dos dados
              pessoais inexatos que lhe digam respeito;
            </li>
            <li>
              Direito à eliminação dos dados (direito ao esquecimento): é o
              direito do usuário de ter seus dados apagados do aplicativo;
            </li>
            <li>
              Direito à limitação do tratamento dos dados: é o direito do
              usuário de limitar o tratamento de seus dados pessoais, podendo
              obtê-la quando contesta a exatidão dos dados, quando o tratamento
              for ilícito, quando o aplicativo não precisar mais dos dados para
              as finalidades propostas e quando tiver se oposto ao tratamento
              dos dados e em caso de tratamento de dados desnecessários;
            </li>
            <li>
              Direito de oposição: é o direito do usuário de, a qualquer
              momento, se opor por motivos relacionados com a sua situação
              particular, ao tratamento dos dados pessoais que lhe digam
              respeito, podendo se opor ainda ao uso de seus dados pessoais para
              definição de perfil de marketing (profiling);
            </li>
            <li>
              Direito de portabilidade dos dados: é o direito do usuário de
              receber os dados pessoais que lhe digam respeito e que tenha
              fornecido ao aplicativo, num formato estruturado, de uso corrente
              e de leitura automática, e o direito de transmitir esses dados a
              outro aplicativo;
            </li>
            <li>
              Direito de não ser submetido a decisões automatizadas: é o direito
              do usuário de não ficar sujeito a nenhuma decisão tomada
              exclusivamente com base no tratamento automatizado, incluindo a
              definição de perfis (profiling), que produza efeitos na sua esfera
              jurídica ou que o afete significativamente de forma similar.
            </li>
          </ul>
          <p class="mt-1">
            O usuário poderá exercer os seus direitos por meio de comunicação
            escrita enviada ao aplicativo com o assunto
            "RGDP-www.appdoit.com.br", especificando:
          </p>
          <ul>
            <li class="mt-1">
              Nome completo ou razão social, número do CPF (Cadastro de Pessoas
              Físicas, da Receita Federal do Brasil) ou CNPJ (Cadastro Nacional
              de Pessoa Jurídica, da Receita Federal do Brasil) e endereço de
              e-mail do usuário e, se for o caso, do seu representante;
            </li>
            <li>Direito que deseja exercer junto ao aplicativo;</li>
            <li>Data do pedido e assinatura do usuário;</li>
            <li>
              Todo documento que possa demonstrar ou justificar o exercício de
              seu direito.
            </li>
          </ul>
          <p class="mt-1">
            O pedido deverá ser enviado ao e-mail:
            <a class="link" href="mailto:contato@appdoit.com.br"
              >contato@appdoit.com.br</a
            >
          </p>
          <p class="mt-1">
            O usuário será informado em caso de retificação ou eliminação dos
            seus dados.
          </p>
          <p class="mt-2">3. Informações coletadas</p>
          <p class="mt-1">3.1. Tipo de dados coletados</p>
          <p class="mt-1">
            Os dados pessoais coletados pelo aplicativo são apenas aqueles
            necessários à identificação do usuário, não sendo coletados dados
            sensíveis do usuário, definidos nos artigos 9º e 10 do Regulamento
            Geral Europeu de Proteção de Dados Pessoais (RGPD) e do artigo 11 da
            Lei federal n. 13.709, de 14 de agosto de 2018.
          </p>
          <p class="mt-1">
            Serão coletados todos os dados necessários ao cadastro do usuário,
            como nome completo ou razão social, número do CPF ou CNPJ, e-mail e
            endereço do usuário, requisitos necessários para acessar
            determinados serviços oferecidos pelo aplicativo.
          </p>
          <p class="mt-1">
            Ainda, serão coletados os dados necessários para a execução do
            contrato de venda ou prestação de serviços eventualmente concluído
            entre o aplicativo e o usuário, referente aos serviços oferecidos,
            sem os quais não será possível a sua execução.
          </p>
          <p class="mt-1">
            Eventualmente poderão ser coletados outras categorias de dados,
            desde que fornecidos com o consentimento do usuário, ou ainda em
            razão de interesse legítimo ou demais motivos permitidos em lei.
          </p>
          <p class="mt-1">
            O usuário se compromete a fornecer exclusivamente os seus dados
            pessoais e não os de terceiros.
          </p>
          <p class="mt-1">
            3.2. Fundamento jurídico para o tratamento dos dados pessoais
          </p>
          <p class="mt-1">
            Ao utilizar os serviços aplicativo, o usuário está consentindo com o
            presente termo e com a sua política de privacidade.
          </p>
          <p class="mt-1">
            O usuário tem o direito de retirar o seu consentimento a qualquer
            momento, não comprometendo a licitude dos dados pessoais tratados
            antes de sua retirada. A retirada do consentimento poderá ser feita
            pelo e-mail:
            <a class="link" href="mailto: contato@appdoit.com.br"
              >contato@appdoit.com.br</a
            >
          </p>
          <p class="mt-3">
            O consentimento dos relativamente ou absolutamente incapazes,
            especialmente de crianças menores de 16 (dezesseis) anos, apenas
            poderá ser feito, respectivamente, se devidamente assistidos ou
            representados.
          </p>
          <p class="mt-1">
            Poderão ainda serem coletados dados pessoais necessários para a
            execução e cumprimento dos serviços contratados pelo usuário no
            aplicativo.
          </p>
          <p class="mt-1">
            O tratamento de dados pessoais sem o consentimento do usuário apenas
            será realizado em razão de interesse legítimo ou para as hipóteses
            previstas em lei, dentre outras, as seguintes:
          </p>
          <ul>
            <li mt-1>
              para o cumprimento de obrigação legal ou regulatória pelo
              controlador;
            </li>
            <li>
              para a realização de estudos por órgão de pesquisa, garantida,
              sempre que possível, a anonimização dos dados pessoais;
            </li>
            <li>
              quando necessário para a execução de contrato ou de procedimentos
              preliminares relacionados a contrato do qual seja parte o usuário,
              a pedido do titular dos dados;
            </li>
            <li>
              para o exercício regular de direitos em processo judicial,
              administrativo ou arbitral, esse último nos termos da Lei nº
              9.307, de 23 de setembro de 1996 (Lei de Arbitragem);
            </li>
            <li>
              para a proteção da vida ou da incolumidade física do titular dos
              dados ou de terceiro;
            </li>
            <li>
              para a tutela da saúde, em procedimento realizado por
              profissionais da área da saúde ou por entidades sanitárias;
            </li>
            <li>
              quando necessário para atender aos interesses legítimos do
              controlador ou de terceiro, exceto no caso de prevalecerem
              direitos e liberdades fundamentais do titular dos dados que exijam
              a proteção dos dados pessoais;
            </li>
            <li>
              para a proteção do crédito, inclusive quanto ao disposto na
              legislação pertinente.
            </li>
          </ul>
          <p class="mt-1">3.3. Finalidades do tratamento dos dados pessoais</p>
          <p class="mt-1">
            Os dados pessoas do usuário coletados pelo aplicativo têm por
            finalidade facilitar, agilizar e cumprir os compromissos
            estabelecidos com o usuário e a fazer cumprir as solicitações
            realizadas por meio do preenchimento de formulários.
          </p>
          <p class="mt-1">
            Os dados pessoais poderão ser utilizados também com uma finalidade
            comercial, para personalizar o conteúdo oferecido ao usuário, bem
            como para dar subsídio ao aplicativo para a melhora da qualidade e
            funcionamento de seus serviços.
          </p>
          <p class="mt-1">
            Os dados de cadastro serão utilizados para permitir o acesso ao
            usuário de determinados conteúdos do aplicativo exclusivos para
            usuários cadastrados.
          </p>
          <p class="mt-1">
            Por fim, serão coletados dados necessários para a execução do
            contrato de venda ou prestação de serviços eventualmente concluído
            entre o aplicativo e o usuário, referente aos serviços oferecidos.
          </p>
          <p class="mt-1">
            Se o aplicativo tiver a intenção de tratar os dados pessoais do
            usuário para outras finalidades ele deverá ser informado sobre as
            outras finalidades, que deverão ser feitas em observação aos mesmos
            direitos e obrigações.
          </p>
          <p class="mt-1">3.4. Prazo de conservação dos dados pessoais</p>
          <p class="mt-1">
            Os dados pessoais do usuário serão conservados por um período não
            superior ao exigido para cumprir os objetivos em razão dos quais
            eles são processados.
          </p>
          <p class="mt-1">
            O período de conservação dos dados são definidos de acordo com os
            seguintes critérios:
          </p>
          <p class="mt-1 ml-2">
            Os dados pessoais do usuário serão armazenados pelo tempo em que o
            usuário mantiver plano ativo com o aplicativo.
          </p>
          <p class="mt-1">
            Os dados pessoais dos usuários apenas poderão ser conservados após o
            término de seu tratamento nas seguintes hipóteses:
          </p>
          <ul>
            <li class="mt-1">
              para o cumprimento de obrigação legal ou regulatória pelo
              controlador;
            </li>
            <li class="mt-1">
              para estudo por órgão de pesquisa, garantida, sempre que possível,
              a anonimização dos dados pessoais;
            </li>
            <li class="mt-1">
              para a transferência a terceiro, desde que respeitados os
              requisitos de tratamento de dados dispostos na legislação;
            </li>
            <li class="mt-1">
              para uso exclusivo do controlador, vedado seu acesso por terceiro,
              e desde que anonimizados os dados.
            </li>
          </ul>
          <p class="mt-1">
            3.5. Destinatários e transferência dos dados pessoais
          </p>
          <p class="mt-1">
            Os dados pessoais do usuário não serão compartilhadas com terceiros,
            que serão tratados apenas por este aplicativo.
          </p>
          <p class="mt-2">4. Do tratamento dos dados pessoais</p>
          <p class="mt-1">
            4.1. Do responsável pelo tratamento dos dados (data controller)
          </p>
          <p class="mt-1">
            O controlador, responsável pelo tratamento dos dados pessoais do
            usuário, é a pessoa física ou jurídica, a autoridade pública, a
            agência ou outro organismo que, individualmente ou em conjunto com
            outras, determina as finalidades e os meios de tratamento de dados
            pessoais.
          </p>
          <p class="mt-1">
            Neste aplicativo, o responsável pelo tratamento dos dados pessoais
            coletados é FLÁVIO ERNESTO ROCHA MATOS ME, representada por Flávio
            Ernesto Rocha Matos, que poderá ser contactado pelo e-mail:
            <a class="link" href="mailto: contato@appdoit.com.br"
              >contato@appdoit.com.br</a
            >
          </p>
          <p class="mt-1">
            O responsável pelo tratamento dos dados se encarregará diretamente
            do tratamento dos dados pessoais do usuário.
          </p>
          <p class="mt-1">
            4.2. Do encarregado de proteção de dados (data protection officer)
          </p>
          <p class="mt-1">
            O encarregado de proteção de dados (data protection officer) é o
            profissional encarregado de informar, aconselhar e controlar o
            responsável pelo tratamento dos dados , bem como os trabalhadores
            que tratem os dados, a respeito das obrigações do aplicativo nos
            termos do Regulamento Geral Europeu de Proteção de Dados (RGDP), da
            Lei federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de
            Dados Pessoais) e de outras disposições de proteção de dados
            presentes na legislação nacional e internacional, em cooperação com
            a autoridade de controle competente.
          </p>
          <p class="mt-1">
            Neste aplicativo o encarregado de proteção de dados (data protection
            officer) é Flávio Ernesto Rocha Matos e poderá ser contactado pelo
            e-mail:
            <a class="link" href="mailto: contato@appdoit.com.br"
              >contato@appdoit.com.br</a
            >.
          </p>
          <p class="mt-2">
            5. Segurança no tratamento dos dados pessoais do usuário
          </p>
          <p class="mt-1">
            O aplicativo se compromete a aplicar as medidas técnicas e
            organizativas adequadas para assegurar um nível de segurança
            adequado ao risco, tendo em conta as técnicas mais avançadas, os
            custos de aplicação e a natureza, o âmbito, o contexto e as
            finalidades do tratamento, bem como os riscos, de probabilidade e
            gravidade variável, para os direitos e liberdades do usuário.
          </p>
          <p class="mt-1">
            Para tanto, o aplicativo utiliza certificado SSL (Secure Socket
            Layer) que garante que os dados pessoais se transmitam de forma
            segura e confidencial, de maneira que a transmissão dos dados entre
            o servidor e o usuário, e em retroalimentação, ocorra de maneira
            totalmente cifrada ou encriptada.
          </p>
          <p class="mt-1">
            No entanto, o aplicativo se exime da responsabilidade por culpa
            exclusiva de terceiro, como em caso de ataque de hackers ou
            crackers, ou culpa exclusiva do usuário, que, por exemplo, transfere
            seus dados a terceiro, exceto se a pirataria se deu em razão de
            falha de segurança do aplicativo. O aplicativo se compromete, ainda,
            a comunicar o usuário em prazo adequado caso ocorra algum tipo de
            violação da segurança de seus dados pessoais que possa lhe causar um
            alto risco para seus direitos e liberdades pessoais.
          </p>
          <p class="mt-1">
            A violação de dados pessoais é uma violação da segurança que
            provoque, de modo acidental ou ilícito, a destruição, a perda, a
            alteração, a divulgação ou o acesso, não autorizados, a dados
            pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo
            de tratamento.
          </p>
          <p class="mt-1">
            Por fim, o aplicativo se compromete a tratar os dados pessoais do
            usuário com confidencialidade, dentro dos limites legais.
          </p>
          <p class="mt-2">6. Dados de navegação (Cookies)</p>
          <p class="mt-1">
            O aplicativo recorre eventualmente às técnicas de "cookies", que lhe
            permitem analisar as estatísticas e as informações sobre a navegação
            do usuário. Os "cookies" são pequenas quantidades de informação que
            são armazenadas pelo navegador do usuário para que o servidor se
            lembre de determinada informação que posteriormente e unicamente o
            servidor que a implementou poderá ler. Podem ser fornecidos, por
            exemplo, dados sobre o dispositivo utilizado pelo usuário e o seu
            local e horário de acesso. Esta coleta de informações busca melhorar
            a navegação, para o conforto do usuário, ao permitir apresentar-lhe
            serviços personalizados, de acordo com suas preferências.
          </p>
          <p class="mt-1">
            Os "cookies" não permitem extrair qualquer informação do disco
            rígido do usuário, nem roubar qualquer tipo de informação pessoal. A
            única maneira na qual informações privadas do usuário formem parte
            dos dados de navegação é que o usuário dê pessoalmente essa
            informação ao servidor.
          </p>
          <p class="mt-1">
            Os "cookies" que permitem identificar uma pessoa são considerados
            dados pessoais. Dessa forma, as mesmas regras descritas
            anteriormente se aplicam aos "cookies".
          </p>
          <p class="mt-1">
            Estes dados de navegação poderão, ainda, ser compartilhados com
            eventuais parceiros do aplicativo, buscando o aprimoramento dos
            produtos e serviços ofertados ao usuário.
          </p>
          <p class="mt-1">
            O usuário poderá se opor ao registro de "cookies" pelo aplicativo,
            bastando desativar esta opção no seu próprio navegador ou aparelho.
            Por outro lado, a desativação deste registro poderá afetar a
            disponibilidade de algumas ferramentas e alguns serviços do
            aplicativo.
          </p>
          <p class="mt-1">6.1. Cookies do aplicativo</p>
          <p class="mt-1">
            Os "cookies" do aplicativo são aqueles cookies que são enviados ao
            computador ou dispositivo do usuário e administrador exclusivamente
            pelo aplicativo, para o seu melhor funcionamento.
          </p>
          <p class="mt-1">
            A informação coletada é usada para melhorar a qualidade do
            aplicativo e o seu conteúdo e experiência para o usuário. Essas
            informações permitem reconhecer o usuário como visitante recorrente
            do aplicativo e adaptar o conteúdo para lhe oferecer conteúdos
            adaptados à sua preferência.
          </p>
          <p class="mt-1">6.2. Cookies de redes sociais</p>
          <p class="mt-1">
            O aplicativo utiliza plugins de redes sociais, que permitem
            acessá-las a partir do aplicativo. Dessa forma, os cookies de redes
            sociais podem ser armazenados no navegador do usuário. Os
            titulares das redes sociais dispõem de sua própria política de
            privacidade e de proteção de dados e cookies, sendo eles, em cada
            caso, responsáveis dos dados pessoais coletados e de suas práticas
            de privacidade. O usuário deve pesquisar junto às redes sociais como
            os seus dados pessoais são tratados. Unicamente a título
            informativo, se indicam os seguintes links em que se poderá
            consultar as políticas de privacidade e cookies de algumas das
            principais redes sociais:
          </p>
          <p class="mt-1 ml-2">
            Facebook:
            <a
              class="link"
              href="https://www.facebook.com/policies/cookies/"
              target="_blank"
              rel="noreferrer"
              >https://www.facebook.com/policies/cookies/</a
            >
          </p>
          <p class="mt-1 ml-2">
            Twitter:
            <a
              class="link"
              href="https://twitter.com/pt/privacy"
              target="_blank"
              rel="noreferrer"
              >https://twitter.com/pt/privacy</a
            >
          </p>
          <p class="mt-1 ml-2">
            Instagram:
            <a
              class="link"
              href="https://help.instagram.com/1896641480634370?ref=ig"
              target="_blank"
              rel="noreferrer"
              >https://help.instagram.com/1896641480634370?ref=ig</a
            >
          </p>
          <p class="mt-1 ml-2">
            Youtube:
            <a
              class="link"
              href="https://policies.google.com/privacy?hl=pt-BR&gl=pt"
              target="_blank"
              rel="noreferrer"
              >https://policies.google.com/privacy?hl=pt-BR&gl=pt</a
            >
          </p>
          <p class="mt-1 ml-2">
            Google+:
            <a
              class="link"
              href="https://policies.google.com/technologies/cookies?hl=pt"
              target="_blank"
              rel="noreferrer"
              >https://policies.google.com/technologies/cookies?hl=pt</a
            >
          </p>
          <p class="mt-1 ml-2">
            Pinterest:
            <a
              class="link"
              href="https://policy.pinterest.com/pt-br/privacy-policy"
              target="_blank"
              rel="noreferrer"
              >https://policy.pinterest.com/pt-br/privacy-policy</a
            >
          </p>
          <p class="mt-1 ml-2">
            LinkedIn:
            <a
              class="link"
              href="https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies"
              target="_blank"
              rel="noreferrer"
              >https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies</a
            >
          </p>
          <p class="mt-1">
            6.3. Gestão dos cookies e configurações do navegador
          </p>
          <p class="mt-1">
            O usuário pode gerir os cookies diretamente em seu navegador.
            Contudo, cancelando os cookies o navegador pode remover preferências
            salvas no aplicativo.
          </p>
          <p class="mt-1">
            A seguir você encontra alguns links que direcionam à seção de ajuda
            dos navegadores mais utilizados, para verificar como gerir os
            cookies:
          </p>
          <p class="mt-1 ml-2">
            Internet explorer:
            <a
              class="link"
              href="https://support.microsoft.com/pt-br/help/17442/windowsinternet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noreferrer"
              >https://support.microsoft.com/pt-br/help/17442/windowsinternet-explorer-delete-manage-cookies</a
            >
          </p>
          <p class="mt-1 ml-2">
            Safari:
            <a
              class="link"
              href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
              target="_blank"
              rel="noreferrer"
              >https://support.apple.com/pt-br/guide/safari/sfri11471/mac</a
            >
          </p>
          <p class="mt-1 ml-2">
            Google Chrome:
            <a
              class="link"
              href="https://support.google.com/chrome/answer/95647?hl=ptBR&hlrm=pt"
              target="_blank"
              rel="noreferrer"
              >https://support.google.com/chrome/answer/95647?hl=ptBR&hlrm=pt</a
            >
          </p>
          <p class="mt-1 ml-2">
            Mozila Firefox:
            <a
              class="link"
              href="https://support.mozilla.org/pt-BR/kb/protecao-aprimorada-contra-rastreamento-firefox-desktop?redirectslug=ative-e-desative-os-cookies-que-os-sites-usam&redirectlocale=pt-BR"
              target="_blank"
              rel="noreferrer"
              >https://support.mozilla.org/pt-BR/kb/protecao-aprimorada-contra-rastreamento-firefox-desktop?redirectslug=ative-e-desative-os-cookies-que-os-sites-usam&redirectlocale=pt-BR</a
            >
          </p>
          <p class="mt-1 ml-2">
            Opera:
            <a
              class="link"
              href="https://www.opera.com/help/tutorials/security/privacy/"
              target="_blank"
              rel="noreferrer"
              >https://www.opera.com/help/tutorials/security/privacy/</a
            >
          </p>
          <p class="mt-2">7. Reclamação a uma autoridade de controle</p>
          <p class="mt-1">
            Sem prejuízo de qualquer outra via de recurso administrativo ou
            judicial, todos os titulares de dados têm direito a apresentar
            reclamação a uma autoridade de controle. A reclamação poderá ser
            feita à autoridade da sede do aplicativo, do país de residência
            habitual do usuário, do seu local de trabalho ou do local onde foi
            alegadamente praticada a infração.
          </p>
        </b>
      </div>
      <div class="text-holder mt-2">
        <span class="title">X. DAS OFERTAS E PRODUTOS</span>
        <p>
          Por intermédio do aplicativo <b>Do It</b>, o editor fornece ao cliente
          um catálogo ou uma loja online apresentando com exatidão os produtos e
          serviços vendidos. Os produtos e serviços estão descritos e
          apresentados com o maior grau de precisão possível, contendo
          informações corretas, claras, precisas, ostensivas e em língua
          portuguesa sobre suas características, qualidades, quantidade,
          composição, preço, garantia, prazos de validade e origem, entre outros
          dados, bem como sobre os riscos que apresentam à saúde e segurança do
          usuário.
        </p>
        <p class="mt-1">
          Os produtos e serviços são colocados à venda até o limite do estoque
          disponível.
        </p>
        <p class="mt-1">
          Os preços e as taxas referentes à venda dos produtos e serviços estão
          precisadas no catálogo ou na loja online.
        </p>
        <p class="mt-1">
          Antes de finalizar a compra sobre determinado produto ou serviço, o
          usuário deverá se informar sobre as suas especificações e sobre a sua
          destinação, bem como a faixa etária para a qual é recomendado. Em se
          tratando de produto ou serviço adquirido no âmbito de ofertas, o
          usuário deverá também observar as condições de aplicação destas.
        </p>
        <p class="mt-1">
          As ofertas serão disponibilizadas no aplicativo de acordo com a
          conveniência do editor. Elas poderão ser concluídas antes do prazo
          previsto, se o estoque for finalizado e não for possível
          restabelecê-lo com os fornecedores do aplicativo.
        </p>
      </div>
      <div class="text-holder mt-2">
        <span class="title">XI. DOS PREÇOS</span>
        <p>
          O vendedor se reserva o direito de modificar os preços dos produtos e
          serviços a qualquer momento, publicando-os no aplicativo <b>Do It</b>.
        </p>
        <p class="mt-1">
          Serão aplicadas as tarifas em vigor no momento do pedido, sob reserva
          de disponibilidade de produtos e serviços nesta data.
        </p>
        <p class="mt-1">
          Os preços serão indicados em reais e não incluirão as taxas de
          entrega, as quais virão especificadas à parte e serão informadas ao
          cliente antes da realização do pedido.
        </p>
        <p class="mt-1">
          O montante total do pedido, incluindo todas as taxas, será indicado
          antes da validação final do pedido
        </p>
      </div>
      <div class="text-holder mt-2">
        <span class="title">XII. DO PAGAMENTO</span>
        <p>
          O pagamento do pedido deverá ser realizado à vista, através dos
          seguintes meios:
        </p>
        <p class="mt-1 ml-2">
          Cartão de crédito via integração segura Gerencianet.
        </p>
      </div>
      <div class="text-holder mt-2">
        <span class="title">XIII. DA ENTREGA</span>
        <p>
          O prazo de entrega dos produtos ou dos serviços adquiridos no
          aplicativo <b>Do It</b> será informado no momento do pedido, em dias
          úteis. O tempo para a entrega é calculado conforme o estoque, a
          região, o processo de emissão da nota fiscal e a preparação do pedido.
        </p>
        <p class="mt-1">
          Após a finalização do pedido, não poderá ser possível alterar a forma
          de pagamento, o endereço de entrega ou as condições de entrega do
          produto, tais como prioridade ou adiantamento.
        </p>
      </div>
      <div class="text-holder mt-2">
        <span class="title">XIV. DO DIREITO DE ARREPENDIMENTO</span>
        <p>
          Dentro do prazo de 7 (sete) dias, contados da data de recebimento do
          produto ou serviço, o cliente poderá desistir da compra e solicitar a
          devolução dos valores pagos, sem necessidade de apresentação de
          quaisquer justificativas.
        </p>
        <p class="mt-1">
          Neste caso, ele deverá fazer retornar ao vendedor o produto ou serviço
          adquirido, nas mesmas condições em que o recebeu.
        </p>
      </div>
      <div class="text-holder mt-2">
        <span class="title">XV. DAS TROCAS E DEVOLUÇÕES</span>
        <p>
          A política de trocas e de devoluções do aplicativo <b>Do It</b> será
          regida conforme o Código de Defesa do Consumidor (Lei Federal n.
          8.078, de 11 de setembro de 1990).
        </p>
        <p class="mt-1">
          Afora a hipótese de arrependimento do cliente, a troca ou a devolução
          dos produtos ou dos serviços adquiridos apenas será realizada mediante
          verificação de vícios de qualidade ou quantidade que os tornem
          impróprios ou inadequados ao consumo a que se destinam ou que lhes
          diminuam o valor. Também poderão ser trocados ou devolvidos os
          produtos ou serviços que apresentem disparidade com as indicações
          constantes do recipiente, da embalagem, rotulagem ou mensagem
          publicitária, respeitadas as variações decorrentes de sua natureza
        </p>
        <p class="mt-1">
          O usuário deverá comunicar o vendedor, por meio de seu serviço de
          atendimento, tão logo constate o vício no produto ou serviço
          adquirido. Se, no prazo máximo de 30 (trinta) dias, não for possível
          resolver o vício ou, independente deste prazo, a substituição das
          partes viciadas puder comprometer a qualidade ou características do
          produto ou serviço, diminuir-lhe o valor ou se tratar de produto ou
          serviço essencial, o cliente poderá optar pela substituição do produto
          por outro de mesma espécie ou pela reexecução do serviço, pela
          devolução da quantia paga ou pelo abatimento proporcional do preço.
        </p>
      </div>
      <div class="text-holder mt-2">
        <span class="title">XVI. DO SERVIÇO DE ATENDIMENTO AO USUÁRIO</span>
        <p>
          Em caso de dúvidas, sugestões ou problemas com a utilização do
          aplicativo <b>Do It</b>, o usuário poderá contatar diretamente o seu
          serviço de atendimento, através do endereço de e-mail:
          <a class="link" href="mailto: contato@appdoit.com.br"
            >contato@appdoit.com.br</a
          >, bem como do telefone: (79) 99965- 1366. Estes serviços de
          atendimento ao usuário estarão disponíveis nos seguintes dias e
          horários:
        </p>
        <p class="mt-1 ml-2">De segunda a sexta, das 8h às 18h</p>
        <p class="mt-1">
          O usuário poderá, ainda, optar por enviar correspondência ao endereço
          da sede do aplicativo <b>Do It</b>, informado no início deste
          documento.
        </p>
      </div>
      <div class="text-holder mt-2">
        <span class="title">XVII. DAS SANÇÕES</span>
        <p>
          Sem prejuízo das demais medidas legais cabíveis, o editor do
          aplicativo Do It poderá, a qualquer momento, advertir, suspender ou
          cancelar a conta do usuário:
        </p>
        <p class="mt-1 ml-1">
          a) que descumprir quaisquer dos dispositivos contidos no presente
          instrumento;
        </p>
        <p class="mt-1 ml-1">b) que descumprir os seus deveres de usuário;</p>
        <p class="mt-1 ml-1">c) que praticar atos fraudulentos ou dolosos;</p>
        <p class="mt-1 ml-1">d) que fornecer qualquer informação incorreta;</p>
        <p class="mt-1 ml-1">
          e) cujo comportamento constitua ou possa vir a importar ofensa ou dano
          a terceiro ou ao próprio aplicativo.
        </p>
      </div>
      <div class="text-holder mt-2">
        <span class="title">XVIII. DAS ALTERAÇÕES</span>
        <p>
          A presente versão dos termos e condições gerais de uso e de compra e
          venda foi atualizada pela última vez em: 19/05/2020.
        </p>
        <p class="mt-1">
          O editor se reserva o direito de modificar, a qualquer momento e sem
          qualquer aviso prévio, o aplicativo e os serviços, bem como as
          presentes normas, especialmente para adaptá-las às evoluções do
          aplicativo <b>Do It</b>, seja pela disponibilização de novas
          funcionalidades, seja pela supressão ou modificação daquelas já
          existentes.
        </p>
        <p class="mt-1">
          Dessa forma, convida-se o usuário a consultar periodicamente esta
          página para verificar as atualizações.
        </p>
        <p class="mt-1">
          O usuário que utilizar o serviço, após realizadas modificações nos
          termos e condições gerais, demonstra a sua concordância com as novas
          normas. Caso discorde de alguma das modificações, deverá pedir,
          imediatamente, o cancelamento de sua conta e apresentar a sua ressalva
          ao serviço de atendimento, se assim o desejar.
        </p>
      </div>
      <div class="text-holder mt-2">
        <span class="title">XIX. DO DIREITO APLICÁVEL E DO FORO</span>
        <p>
          Para a solução das controvérsias decorrentes do presente instrumento,
          será aplicado integralmente o Direito brasileiro, notadamente a sua
          legislação consumerista.
        </p>
        <p class="mt-1">
          Os eventuais litígios deverão ser apresentados no foro da comarca em
          que se encontra a sede do editor do aplicativo.
        </p>
      </div>
      <div class="text-holder text-center mt-4">
        <em><p>Seja bem-vindo(a)!</p>
        <p class="mt-1 mb-3">A equipe do aplicativo <b>Do It</b> lhe deseja uma excelente navegação!</p></em>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
export default {
  components: {
    Header,
  },
};
</script>

<style lang="scss" scoped>
.text-holder {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.text-holder .title {
  font-family: fontBold;
  font-size: 1.8rem;
  text-align: left;
  margin-bottom: 15px;
  text-transform: uppercase;
  &.subtitle {
    font-size: 1.2rem;
    text-align: start;
    text-transform: none;
  }
}

.text-holder .main-title {
  text-align: center;
}

.text-holder p {
  font-family: fontRegular;
  font-size: 1.6rem;
  line-height: 1.4;
}

.text-holder .no-indent {
  margin-top: 15px !important;
  text-indent: 0 !important;
}

.text-holder {
  ol {
    padding-left: 2em;
    li {
      margin-top: 0.5rem;
      font-family: fontRegular;
      font-size: 1.6rem;
      line-height: 1.4;
    }
  }
}
.text-holder ul li {
  margin-left: 3em;
  margin-top: 0.5rem;
  font-family: fontRegular;
  font-size: 1.6rem;
  line-height: 1.4;
  list-style: initial;
}
.link {
  margin-top: 0.3em;
  font-family: fontRegular;
  font-size: 1.6rem;
  line-height: 1.3;
  border-bottom: 1px solid var(--primary);
}
.mt-4 {
  margin-top: 8.3rem;
}
</style>